import React, { useEffect, useState } from 'react'; // Import React and hooks
import logo from './logo.svg'; // Import logo image
import './App.css'; // Import styles

function App() {
  // State to store the message from the Flask backend
  const [message, setMessage] = useState('');

  // useEffect to fetch message from Flask backend on component load
  useEffect(() => {
    fetch('http://127.0.0.1:5000/api/hello')
      .then((response) => response.json())
      .then((data) => setMessage(data.message))
      .catch((error) => console.error('Error fetching message:', error));
  }, []);

  // JSX to render the UI
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <h1>Message from Flask Backend:</h1>
        <p>{message}</p>
      </header>
    </div>
  );
}

export default App;
